.#{$namespace}select {
    border: 2px solid $color-light-grey;
    appearance: none;
    max-width: calc(100% + 2 * 2px); //border
    font-family: $base-font-family;
    font-size: 12px;
    background: none;
    border-radius: 0;
    color: $color-base;
    padding: 0 18px;
    line-height: 41px;
    z-index: 2;
    $root: &;

    @media #{$phone-lg-up} {
        padding: 0 40px;
        line-height: 70px;
    }

    @media #{$tablet-up} {
        max-width: 250px;
        padding: 0 18px;
        line-height: 41px;
    }

    &::-ms-expand {
        display: none;
    }

    &:focus {
        outline: none;
    }

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $color-base;
    }

    & ~ .#{$namespace}icon {
        position: absolute;
        right: 12px;
        top: 0;
        width: 12px;
        height: 100%;
        fill: $color-base;
        z-index: 1;
    }

    &:focus ~ .#{$namespace}icon {
        transform: rotate(180deg);
        -moz-transform: none;
    }

    &--country {
        border-right: none;
        font-size: 18px;
        position: relative;
        padding-left: 30px + 18px;
        padding-right: 30px + 18px;
        //@include iconBgMultiple(($icon-location, $color-light-grey, $icon-arrow-down, $color-orange, $icon-vertical-line, $color-light-grey));
        background-repeat: no-repeat;
        background-position: left 10px center, right 10px center, right center;
        background-size: 20px 26px, 15px 9px, auto;

        &#{$root}:focus {
            //@include iconBgMultiple(($icon-location, $color-orange, $icon-arrow-up, $color-orange));
        }
    }

    &--radius {
        font-size: 18px;
        padding: 0 30px 0 10px;
        margin: -2px 0 -2px -2px;
        line-height: 57px;

        @media #{$phone-lg-up} {
            padding: 0 40px 0 10px;
            font-size: 21px;
            line-height: 80px;
        }

        @media #{$tablet-up} {
            margin: -2px;
            font-size: 18px;
            line-height: 1;
        }

        & ~ .#{$namespace}icon {
            position: absolute;
            right: 10px;
            top: 0;
            width: 15px;
            height: 100%;

            @media #{$phone-lg-up} {
                right: 15px;
                width: 21px;
            }

            @media #{$tablet-up} {
                width: 15px;
            }
        }

        &:focus {
            z-index: 4;
        }
    }

    &--no-border {
        border: none;
    }

    &--sort {
        line-height: 1;
        padding: 0 13px + 5px 0 0;
        font-size: 16px;

        & ~ .#{$namespace}icon {
            right: 0;
            width: 13px;
        }
    }
}