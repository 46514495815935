.#{$namespace}widget-simple {
    font-family: $base-font-family;
    padding: 40px 25px;
    background: #E6E7E9;
    color: $color-base;
    overflow: auto;

    @media #{$tablet-up} {
        padding: 40px 20px;
    }

    &__container {
        @media #{$tablet-up} {
            display: flex;
            justify-content: center;

        }
    }

    &__inputs-wrapper {
        width: 100%;
        & > * {
            margin-bottom: 20px;
        }

        @media #{$phone-lg-up} {
            & > * {
                margin-bottom: 40px;
            }
        }

        @media #{$tablet-up} {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-top: -15px;

            & > * {
                margin-top: 15px;
                margin-bottom: 0;
            }
        }
    }

    &__city-wrapper {
        flex-grow: 1;
        width: 0;
        margin-right: -4px;

        @media #{$tablet-up} {
            width: auto;
        }
    }

    &__radius-wrapper {
        margin-right: -2px;

        @media #{$tablet-up} {
            margin-right: 0;
        }
    }

    &__input-wrapper {
        flex-grow: 1;
    }

    &__input {
        display: block;
        width: 100%;
        flex-basis: 271px;
        box-sizing: border-box;
        flex-grow: 1;
        padding-top: 18px;
        padding-bottom: 18px;

        @media #{$phone-lg-only} {
            padding-top: 28px;
            padding-bottom: 28px;
        }

        @media #{$tablet-up} {
            margin-right: 25px;
            width: auto;
        }
    }

    &__location-wrapper {
        display: flex;
        justify-content: center;
        border: 2px solid $color-light-grey;
        background-color: $color-white;
    }

    &__button {
        margin-left: 5px;
        float: right;

        @media #{$tablet-up} {
            margin-left: 25px;
            clear: both;
        }
    }

    &__separator {
        width: 2px;
        background: #ffffff;
        z-index: 3;
        @include iconBg($icon-vertical-line-64, $color-light-grey);
        background-size: 1px 34px;
        background-position: center;

        @media #{$phone-lg-up} {
            background-size: 1px 61px;
        }

        @media #{$tablet-up} {
            background-size: 1px 44px;
        }
    }

    &__clear-wrapper {
        float: right;
        text-transform: uppercase;
        font-size: 13px;
        transition: color .1s linear;
        font-weight: 700;

        @media #{$phone-lg-up} {
            font-size: 16px;
            float: left;
            line-height: 86px;
        }

        @media #{$tablet-up} {
            clear: left;
            width: 100%;
            text-align: right;
            font-size: 12px;
            margin-top: 10px;
            line-height: 1;
        }

        & .#{$namespace}icon {
            display: block;
            float: right;
            width: 13px;
            height: 18px;
            margin-left: 10px;
            background-size: 20px;
            fill: $color-base;
            transition: fill .1s linear;

            @media #{$phone-lg-up} {
                width: 20px;
                height: 86px;
                margin-left: 15px;
            }

            @media #{$tablet-up} {
                width: 11px;
                height: 11px;
            }

            .no-touch &:hover {
                color: $color-dark-grey;

                & .#{$namespace}icon {
                    fill: $color-dark-grey;
                }
            }
        }
    }

    &__clear {
        cursor: pointer;
    }
}