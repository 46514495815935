.#{$namespace}input {
    padding: 0 15px;
    border: 2px solid $color-light-grey;
    font-size: 18px;
    position: relative;
    color: $color-base;
    box-sizing: inherit;
    max-width: 100%;
    appearance: none;
    border-radius: 0;
    $root: &;

    @media #{$phone-lg-up} {
        padding: 0 30px;
        font-size: 21px;
    }

    @media #{$tablet-up} {
        padding: 0 18px;
        font-size: 18px;
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        font-family: $base-font-family;
    }

    &--with-icon {
        padding-left: 20px + 21px + 20px;

        @media #{$phone-lg-up} {
            padding-left: 20px + 29px + 28px;
        }

        @media #{$tablet-up} {
            padding-left: 20px + 21px + 20px;
        }
    }

    &--what {
        @extend #{$root}--with-icon;

        & ~ .#{$namespace}icon {
            position: absolute;
            top: 0;
            left: 21px;
            width: 21px;
            height: 100%;
            fill: $color-light-grey;

            @media #{$phone-lg-only} {
                width: 29px;
            }
        }
    }

    &--where {
        @extend #{$root}--with-icon;
        flex-grow: 1;
        margin: -2px;
        box-sizing: border-box;
        padding-top: 18px;
        padding-bottom: 18px;

        @media #{$phone-lg-only} {
            padding-top: 28px;
            padding-bottom: 28px;
        }

        &:focus {
            z-index: 4;
        }

        & ~ .#{$namespace}icon {
            position: absolute;
            top: 0;
            left: 19px;
            width: 20px;
            height: 100%;
            fill: $color-light-grey;
            z-index: 10;

            @media #{$phone-lg-only} {
                width: 28px;
            }
        }
    }

    &--no-border {
        border: none;
    }
}