.#{$namespace}pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 18px;
    justify-content: space-between;
    $root: &;

    @media #{$tablet-up} {
        display: inline-flex;
        flex-wrap: nowrap;
    }

    &__numbers {
        display: flex;
        margin: 10px 0;
        width: 100%;
        order: -1;
        justify-content: space-around;

        @media #{$tablet-up} {
            margin: 0;
            width: auto;
            order: 0;
        }
    }

    &__dots {
        text-decoration: none;
        font-weight: 700;
        color: $color-base;
        border-bottom: 4px solid transparent;
        min-width: 22px;
        line-height: 25px;
        width: calc(100% / 7);

        @media #{$phone-lg-up} {
            line-height: 40px;
            width: calc(100% / 9);
        }

        @media #{$tablet-up} {
            line-height: 18px;
            width: auto;
            padding: 0 5px;
        }
    }

    &__number,
    &__button {
        @extend #{$root}__dots;

        & .#{$namespace}icon {
            fill: $color-base;
        }

        &--disabled {
            color: $color-grey;
            cursor: default;

            & .#{$namespace}icon {
                fill: $color-light-grey !important;
            }

            .no-touch &:hover {
                color: $color-grey !important;
                border-bottom-color: transparent !important;

                & .#{$namespace}icon {
                    fill: $color-light-grey !important;
                }
            }
        }
    }

    &__number {
        margin-bottom: -4px;

        @media #{$tablet-up} {
            padding: 0;
        }

        &--active {
            & #{$root}__number-text {
                color: $color-orange;
                border-bottom-color: $color-orange;
            }
        }
    }

    &__button {

        & .#{$namespace}icon {
            height: 25px;

            @media #{$phone-lg-up} {
                height: 35px;
            }

            @media #{$tablet-up} {
                height: 15px;
            }
        }
    }

    &__number-text {
        font-size: 20px;
        padding: 2px 5px;
        border-bottom: 4px solid transparent;
        min-width: 22px;
        display: inline-block;

        @media #{$phone-lg-up} {
            font-size: 23px;
            padding: 4px 15px;
        }

        @media #{$tablet-up} {
            padding: 5px;
            font-size: 16px;
        }
    }

    &__button {
        padding: 7px 0 3px 0;

        &:first-child {
            margin-right: 20px;
        }

        &:last-child {
            margin-left: 20px;
        }
    }
}