.#{$namespace}autocomplete {
    position: absolute;
    background: white;
    max-height: 300px;
    border: 2px solid $color-light-grey;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1000;
    font-family: $base-font-family;
    padding: 10px 20px;
    color: $color-base;
    box-shadow: -5px 5px 0 0px transparentize(#000000, 0.85);

    &__suggestion {
        padding: 5px 0;
        cursor: pointer;
        font-family: $base-font-family;
        font-size: 12px;

        .no-touch &:hover {
            font-weight: 700;
        }

        &--selected {
            font-weight: 700;
        }

        &--disabled {
            cursor: default;

            .no-touch &:hover {
                font-weight: 500;
            }
        }
    }
    &.hidden {
        display: none;
    }
}