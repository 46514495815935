.#{$namespace}result {
    color: #000;
    $root: &;
    background: transparent;
    position: relative;
    z-index: 1;
    overflow: visible;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    &:first-child {
        #{$root}__link {
            padding-top: 20px;
            border-top: 2px solid $color-light-grey;

            &:before {
                margin-top: 15px;
            }
        }

        .no-touch &:hover {
            #{$root}__link {
                &:before {
                    max-height: calc(100% - 30px);
                }
            }
        }
    }

    &__link {
        text-decoration: none;
        color: $color-base;
        padding: 0 0 20px 0;
        display: block;
        cursor: pointer;
        position: relative;
        border-bottom: 2px solid $color-light-grey;
        margin-bottom: 20px;

        .no-touch & {
            padding-left: 15px;
        }

        @media #{$laptop-up} {
            padding-top: 5px;
            margin-bottom: 15px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            max-height: 0;
            width: 4px;
            background: $color-orange;

            .no-touch #{$root}:hover & {
                height: 100%;
                max-height: calc(100% - 15px);
                top: 0;
            }

            @supports not (( -ms-ime-align: auto ) or ( -ms-accelerator: true)) {
                transition: max-height .2s linear, top .2s linear;
            }
        }

        &:after {
            content: ' ';
            display: table;
            clear: both;
        }
    }

    &__logo {
        padding: 20px 0;
        display: block;

        @media #{$phone-lg-up} {
            display: inline-block;
        }
    }

    &__title {
        font-size: 21px;
        font-weight: 700;
        padding: 0 0 10px;
        transition: color .3s linear;
        color: #000;
        padding-left: 30px;

        @media #{$phone-lg-up} {
            font-size: 24px;
        }

        & .#{$namespace}icon {
            display: inline-block;
            width: 15px;
            height: 19px;
            margin-right: 15px;
            margin-left: -30px;
            fill: $color-light-grey;
        }
    }

    &__location-details {

        & .#{$namespace}icon {
            display: inline-block;
            width: 15px;
            height: 19px;
            margin-right: 15px;
            fill: $color-light-grey;
        }
    }

    &__location-item {
        &:after {
            content: ' / ';
            display: inline;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    &__date {
        display: block;
        text-align: right;

        @media #{$phone-lg-up} {
            float: right;
            clear: both;
            margin-left: 10px;
        }

    }

    &__favorite {
        float: right;
        clear: both;
        margin-left: 10px;
        margin-top: 10px;
        position: relative;
        width: 30px;
        height: 30px;

        @media #{$phone-lg-only} {
            width: 36px;
            height: 36px;
        }

        @media #{$laptop-up} {
            margin-left: 40px;
        }

        .no-touch &:hover {
            & .cs-icon--favorite-fill {
                opacity: 1;
            }
        }

        & .cs-icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            transition: opacity .1s linear;
        }

        & .cs-icon--favorite-fill {
            opacity: 0;
        }

        &--selected {
            & .cs-icon--favorite-fill {
                opacity: 1;
            }
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @media #{$tablet-up} {
            flex-wrap: nowrap;
        }

        &--main-info {
            @media #{$phone-lg-up} {
                margin-bottom: -32px;
            }

            @media #{$tablet-up} {
                margin-bottom: -34px;
            }
        }
    }

    &__column {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;

        @media #{$tablet-up} {
            flex-basis: 50%;
        }

        &:empty {
            flex-basis: 0;
        }

        &--small {
            &:not(:empty) {
                padding: 20px;
            }

            @media #{$tablet-up} {
                font-size: 14px;
                padding: 0;
            }
        }
    }

    &__description {
        font-size: 14px;

        @media #{$phone-lg-up} {
            font-size: 16px;
            padding-top: 20px;
            margin-top: 20px;
        }

        @media #{$tablet-up} {
            padding: 20px 0 0 30px;
        }

        & ~ #{$root}__additional-date-wrapper {
            margin-top: 10px;
        }

        a {
            color: $color-orange;
            text-decoration: none;
            font-weight: 700;
            transition: color .1s linear;
        }
        ul {
            li {
                list-style-type: none;
                &:before {
                    content: '\25CF';
                    color: $color-orange;
                    padding-right: 0.5em;
                    margin-left: -20px;
                }
            }
        }
        ol {
            list-style-type: none;
            counter-reset: item;
            li {
                &:before {
                    content: counter(item) ". ";
                    counter-increment: item;
                    color: $color-orange;
                    margin-left: -20px;
                    font-size: 14px;
                    font-weight: 700;
                    padding-right: 0.2em;
                }
            }
        }

    }

    &__expand {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        position: absolute;
        bottom: -21px;
        width: 100%;

        .no-touch & {
            margin-left: -15px;
        }

        @media #{$laptop-up} {
            bottom: -16px;
        }
    }

    &__expand-icon {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 40px;
        text-align: center;
        transition: transform .2s ease-in-out;

        @media #{$phone-lg-only} {
            margin-top: 10px;
        }

        @media #{$laptop-up} {
            width: 30px;
            height: 30px;
        }

        & .#{$namespace}icon {
            width: 100%;
            height: 100%;
            display: inline-block;
            fill: #000;
        }

        &--open {
            transform: scaleY(-1);
        }
    }

    &__no-result {
        padding: 0 0 30px 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        & .#{$namespace}icon {
            display: block;
            width: 110px;
            height: 110px;
            padding-right: 30px;
        }
    }

    &__no-result-text {
        color: $color-base;
    }

    &__share {
        float: right;
        text-align: right;

        @media #{$phone-xs-only} {
            margin-top: 10px;
        }

        @media #{$phone-lg-up} {
            min-height: 35px;
        }
    }

    &__share-icon {
        margin-left: 10px;
        display: inline-block;
        width: 30px;
        height: 30px;

        @media #{$phone-lg-only} {
            width: 36px;
            height: 36px;
        }
    }

    &__company-name {
        font-weight: bold;
    }

    &__hidden-fields {
        max-height: 0;
        padding: 0 0 0 15px;
        overflow: hidden;
        /* box-shadow: inset 0 8px 6px -6px $color-light-grey,
                     inset 0 -8px 6px -6px $color-light-grey;*/
        transition: max-height .3s ease-in-out, padding .3s linear;
        box-sizing: border-box;

        &--visible {
            border-bottom: 2px solid $color-light-grey;
            padding: 0 0 15px 15px;
            margin-bottom: 20px;

            @media #{$laptop-up} {
                margin-bottom: 15px;
            }
        }
    }
}